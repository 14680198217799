<template>
  <div class="col-lg-auto col-12 mb-3 mb-lg-0 px-lg-0">
    <div class="gold-text text-center bold my-2 mt-lg-0">
      Set/Enter <span class="d-none d-lg-inline-block">Your Donation</span> Amount:
    </div>
    <div class="row mx-0 justify-content-center align-items-center">
      <DefaultAmountSelect v-model="selectedAmount" @useSelected="useSelected" />
      <CurrencyField type="text" placeholder="0.00" id="customamount" v-model="customAmount" :currencySymbol="defaultCurrency" @checkAmount="checkAmount" @customFocus="customFocus" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    CurrencyField: defineAsyncComponent(() => import('../components/CurrencyField.vue')),
    DefaultAmountSelect: defineAsyncComponent(() => import('../components/DefaultAmountSelect.vue'))
  },
  props: ['modelValue', 'isAdded'],
  emits: ['update:modelValue'],
  name: 'Enter Amount',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      selectedAmount: '5',
      customAmount: ''
    }
  },
  mounted () {
    this.setDefaultAmount()
  },
  watch: {
    isAdded () {
      if (this.isAdded) {
        this.setDefaultAmount()
      }
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    value: {
      get () {
        return this.modelValue
      },
      async set (value) {
        await this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    checkAmount () {
      if (this.customAmount || this.customAmount === 0 || this.customAmount === '') {
        this.selectedAmount = null
        this.value = this.customAmount
      }
    },
    customFocus () {
      this.selectedAmount = null
      this.value = this.customAmount
    },
    useSelected () {
      this.customAmount = null
      this.value = this.selectedAmount
    },
    setDefaultAmount () {
      if (this.isUK) {
        this.selectedAmount = '5'
      } else {
        this.selectedAmount = '100'
      }
      this.useSelected()
    }
  }
}
</script>
